import { AbstractIntegrationSettings } from '@/services/integrations/settings/AbstractIntegrationSettings';

class Zapier extends AbstractIntegrationSettings {
  constructor() {
    super();
    this.type = 'zapier';
  }

  setFromExisting(integrationData) {
    this.id = integrationData?.id || '';
    this.campaignIntegrationId = integrationData._id || '';
    this.name = integrationData?.global?.data?.name || '';
    this.callbackUrl = integrationData?.global?.data?.callbackUrl || '';

    this.bindings = integrationData?.bindings || [];
  }

  getGlobals() {
    return {
      callbackUrl: this.callbackUrl,
      name: this.name,
    };
  }

  getCampaignSettings() {
    return {
      bindings: this.bindings,
    };
  }
}

export { Zapier };
